import config from "@/utils/config.js";
import wx from "weixin-js-sdk";
import { getJsSdk } from "@/utils/api.js";
import store from "@/store";

export function getWxJsConfig() {
  console.log(window.location.href.split("#"));
  let url = window.location.href.split("#")[0];
  getJsSdk({ url })
    .then((res) => {
      if (res.code == 200) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来,如果是在PC上打开的话会直接打印log。
          appId: config.appid, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp + "", // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "updateAppMessageShareData", //分享给朋友
            "updateTimelineShareData", //分享给朋友圈
            "openLocation",
          ], // 必填，需要使用的JS接口列表,可参考官方文档可授权的接口
        });

        let share_img = res.data.share_img;
        let share_link = url + "#/invite?invite_id=" + store.state.userInfo.id;
        wx.updateAppMessageShareData({
          title:
            (store.state.userInfo.id > 0
              ? store.state.userInfo.nickname
              : "曼巴篮球") + "喊你一起来打球啦~",
          desc:
            (store.state.userInfo.id > 0
              ? store.state.userInfo.nickname
              : "曼巴篮球") + "喊你一起来打球啦~",
          link: share_link,
          imgUrl: share_img,
          success: function (data) {
            console.log("shezhi");
          },
        });

        //监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
        wx.updateTimelineShareData({
          title:
            (store.state.userInfo.id > 0 ||
            store.state.userInfo.nickname.length > 0
              ? store.state.userInfo.nickname
              : "曼巴篮球") + "喊你一起来打球啦~", // 分享标题
          desc:
            (store.state.userInfo.id > 0 ||
            store.state.userInfo.nickname.length > 0
              ? store.state.userInfo.nickname
              : "曼巴篮球") + "喊你一起来打球啦~",
          link: share_link,
          imgUrl: share_img, // 分享图标
          success: function () {
            // 设置成功
            console.log("shezhi");
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
