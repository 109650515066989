import Vue from 'vue'
import VueRouter from 'vue-router'
import explain from '../views/explain.vue' //简介
import recharge from '../views/recharge.vue' //充值
import integrallist from '../views/integrallist.vue' //积分变动记录
import refundlist from '../views/refundlist.vue' //退款记录
import exchangelist from '../views/exchangelist.vue' //兑换记录
import rechargelist from '../views/rechargelist.vue' //充值记录
import spendlist from '../views/spendlist.vue' //消费记录
import pay from '../views/pay.vue' //支付


Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		redirect: '/entry',
	},
	{
		path: '/entry',
		name: 'entry',
		component: () => import('../views/entry.vue'),
		meta:{title: '曼巴篮球'}
	},
	{
	  path: '/index',
	  name: 'indexs',
	  redirect: '/botnav'
	},
	/* {
		path: '/index',
		name: 'index',
		component: () => import('../views/index.vue'),
		meta:{title: '首页'}
	}, */
	{
		path: '/botnav',
		name: 'botnav',
		component: () => import('../views/botnav.vue'),
		redirect: '/botnav/index',
		children:[
		  {
		    path: 'index',
		    name: 'index',
		    component: () => import('../views/index.vue'),
			meta:{title: '曼巴篮球'}
		  },
			{
			  path: 'mine',
			  name: 'mine',
			  component: () => import('../views/mine.vue'),
			  meta:{title: '我的'}
			},
		]
	},
	{
		path: '/explain',
		name: 'explain',
		component: explain,
		meta:{title: '详情'}
	},
	{
		path: '/recharge',
		name: 'recharge',
		component: recharge,
		meta:{title: '充值中心'}
	},
	{
		path: '/refundlist',
		name: 'refundlist',
		component: refundlist,
		meta:{title: '退款记录'}
	},
	{
		path: '/rechargelist',
		name: 'rechargelist',
		component: rechargelist,
		meta:{title: '充值记录'}
	},
	{
		path: '/spendlist',
		name: 'spendlist',
		component: spendlist,
		meta:{title: '消费记录'}
	},
	{
		path: '/pay',
		name: 'pay',
		component: pay,
		meta:{title: '订单详情'}
	},
	{
		path: '/bind-mobile',
		name: 'BindMobile',
		component: () => import('../views/bindMobile.vue'),
		meta:{title: '绑定手机号'}
	},
	{
		path: '/integral-shop',
		name: 'IntegralShop',
		component: () => import('../views/integralShop.vue'),
		meta:{title: '积分商城'}
	},
	{
		path: '/integral-goods',
		name: 'IntegralGoods',
		component: () => import('../views/integralGoods.vue'),
		meta:{title: '商品详情'}
	},
	{
		path: '/exchangelist',
		name: 'Exchangelist',
		component: exchangelist,
		meta:{title: '兑换记录'}
	},
	{
		path: '/integrallist',
		name: 'Integrallist',
		component: integrallist,
		meta:{title: '积分变动记录'}
	},
	{
		path: '/invite',
		name: 'Invite',
		component: () => import('../views/invite.vue'),
		meta:{title: '邀请好友'}
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => { // 判断路由是否需要设置标题
	if (to.meta.title) {
	   document.title = to.meta.title
	}
    next() 
})

export default router
