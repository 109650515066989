<template>
	<div class="layindex">
		<!-- <div class="telbox">
			<img class="icontel" src="@/assets/icon-tel.png" alt="">
			<span class="teltext">电话</span>
		</div> -->
		<v-telephone></v-telephone>
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<div class="explainbox">
			<div class="title">{{ articledetail.title }}</div>
			<!-- <p style="font-size: 15px; margin-bottom: 15px;">包场：</p>
			包场人数半场限定12人，全场24人，其他超过人数则按照30元每人计费。包场距离预约开始时间24小时以上，取消预约退款100%。距离预约开始时间12小时内，取消预约退款50%。 -->
			<div v-html="articledetail.content"></div>
		</div>
	</div>
</template>

<script>
import telephone from '@/components/telephone.vue'
export default {
	name: 'explain',
	components: {
		'v-telephone': telephone,
	},
	created () {
		this.getArticledetail()
	},
	data () {
		return {
			articledetail: {}
		}
	},
	methods: {
		getArticledetail () {
			this.$http.getArticledetail({ 'article_id': this.$route.query.articleId }).then(res => {
				if (res.code == 200) {
					this.articledetail = res.data;
				}
			}).catch(err => {
				console.log(err);
			});
		},
		goindex () {
			this.$router.push({
				path: '/botnav/index'
			});
		}
	}
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	height: 100%;
	position: relative;
	background: url(../assets/indexbg.png) no-repeat center;
	background-size: cover;
	overflow: hidden;
	z-index: 9;

	.telbox {
		position: absolute;
		box-sizing: border-box;
		width: 57px;
		padding: 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		right: 10px;
		top: 10px;
		z-index: 10;
		background-color: #F9A709;
		border-radius: 20px;

		.icontel {
			width: 14px;
			height: 15px;
		}

		.teltext {
			font-size: 12px;
			color: #FFFFFF;
		}
	}

	.logoname {
		width: 158px;
		height: 35px;
		margin: 37px auto 0;
		display: block;
	}

	.explainbox {
		width: 354px;
		min-height: 473px;
		margin: 26px auto 0;
		box-sizing: border-box;
		padding: 25px;
		border-radius: 15px;
		background-color: #FFFFFF;
		font-size: 14px;
		line-height: 24px;
		color: #2A2A2A;

		.title {
			font-size: 16px;
			color: #6468B6;
			margin-bottom: 20px;
			font-weight: bold;
		}
	}
}
</style>
