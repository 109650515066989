<template>
	<div class="nodata">
		<section class="layout-nodata">
			<img src="@/assets/nodata.png" width="100%"/>
		</section>
		<div class="des"><slot>{{msg}}</slot></div>
	</div>
</template>

<script>
	export default {
	  name: 'nodata',
	  props: {
	    msg: String
	  }
	}
</script>

<style lang="less" scoped>
.nodata{
	position: relative;
	width: 100%;
	.layout-nodata{
		width: 114px;
		height: 90px;
		margin: 15% auto 0;
	}
	.des{
		text-align: center; color:#6468B6; font-size: 13px; margin-top: 30px; letter-spacing: 1px; font-weight: bold;
	}
}

</style>