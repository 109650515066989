<template>
	<div class="layindex">
		<div class="payinfor">
			<img class="tabball" src="../assets/tabball.png" />
			<div class="title">本次充值</div>
			<div class="order">（{{orderdata.order_sn}}）</div>
			<div class="price">¥ {{orderdata.recharge_money}}</div>
		</div>
		<ul class="paylist">
			<li>
				<div class="name" style="font-weight: bold;">订单详情</div>
			</li>
			<li>
				<div class="name">{{orderdata.type==1?'散客':'会员'}}充值</div>
				<div class="price">¥{{orderdata.recharge_money}} <span v-if="orderdata.give_money>0">(送{{orderdata.give_money}}元)</span></div>
			</li>
			<li>
				<div class="name">待支付金额</div>
				<div class="price">¥{{orderdata.pay_money}}</div>
			</li>
		</ul>
		<div class="btnbox" v-if="isDisable" @click="gopay">微信支付</div>
		<div class="btnbox" v-if="!isDisable" style="background-color: #BCC5D3;">微信支付</div>
	</div>
</template>

<script>
	export default {
		name: 'pay',
		created() {
			this.orderDetail()
		},
		data() {
			return {
				isDisable: false,
				orderdata: {},
			}
		},
		methods: {
			orderDetail() {
				this.$http.getorderDetail({'order_id': this.$route.query.orderId}).then(res => {
					if (res.code == 200) {
						this.isDisable = true;
						this.orderdata = res.data;
					}
				}).catch(err => {
					console.log(err);
				});
			},
			gopay() {
				if (typeof WeixinJSBridge == "undefined") {
					if (document.addEventListener) {
						document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
					} else if (document.attachEvent) {
						document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
						document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
					}
				} else {
					this.onBridgeReady();
				}
			},
			onBridgeReady() {
				let that=this;
				this.$http.orderpay({'order_id':this.$route.query.orderId}).then(rep => {
					if (rep.code == 200) {
						WeixinJSBridge.invoke(
							'getBrandWCPayRequest', rep.data.paymentConfig,
							function(res) {
								console.log(res)
								if (res.err_msg === 'get_brand_wcpay_request:ok') {
									that.showToastTxtOnly('微信支付成功')
									setTimeout(() => {
										// this.$router.replace({path:'/botnav/mine'})
										that.$router.go(-2)
									}, 2000)
									
								} else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
									that.showToastTxtOnly('用户取消支付')
								} else if (res.err_msg === 'get_brand_wcpay_request:fail') {
									that.showToastTxtOnly('网络异常,请重试')
								}
							}
						)
						setTimeout(() => {
							this.isDisable = true
						}, 3000)
					}
				}).catch(error => {
					console.log(error)
					setTimeout(() => {
						this.isDisable = true
					}, 3000)
				})
			},
			showToastTxtOnly(text) {
				this.toast = this.$createToast({
					txt: text,
					type: 'txt'
				})
				this.toast.show()
			},
			goindex() {
				this.$router.push({
					path: '/botnav/index'
				});
			}
		}
	}
</script>

<style scoped lang="less">
	.layindex {
		width: 100%;
		height: 100%;
		position: relative;
		background-color: #f7f6f2;
		z-index: 1;

		.payinfor {
			width: 100%;
			box-sizing: border-box;
			background-color: #FFFFFF;
			padding: 20px 0 30px;
			text-align: center;
			margin-bottom: 20px;

			.tabball {
				width: 55px;
				height: 56px;
			}

			.title {
				font-size: 15px;
				letter-spacing: 1px;
				color: #6b7171;
				font-weight: bold;
				margin-top: 10px;
			}

			.order {
				font-size: 13px;
				letter-spacing: 0px;
				color: #6b7171;
				margin-top: 10px;
			}

			.price {
				font-size: 30px;
				letter-spacing: 1px;
				color: #000000;
				font-weight: bold;
				margin-top: 15px;
			}
		}

		.paylist {
			width: 100%;
			box-sizing: border-box;
			background-color: #FFFFFF;

			li {
				width: 100%;
				box-sizing: border-box;
				padding: 13px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #eeeeee;

				.name {
					color: #313131;
					font-size: 13px;
					letter-spacing: 1px;
				}

				.price {
					color: #313131;
					font-size: 13px;
				}
			}
		}

		.btnbox {
			width: 304px;
			height: 48px;
			text-align: center;
			line-height: 48px;
			background: #F9A709;
			box-shadow: 0px 4px 9px 1px rgba(33, 64, 135, 0.2);
			opacity: 0.9;
			border-radius: 48px;
			font-weight: 400;
			color: #FFFFFF;
			font-size: 20px;
			letter-spacing: 2px;
			position: fixed;
			left: 50%;
			bottom: 65px;
			transform: translate(-50%, 0);
			z-index: 99;
		}
	}
</style>
