<template>
	<div class="layindex">
		<div class="refunddes" @click="goexplain">
			<img class="icon" src="@/assets/icon-whitetips.png" alt="">充值说明
		</div>
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<div class="fitbox">
			<div class="titlebox">散客预充</div>
			<div class="fitcont">
				<div class="conttext">{{ moneylist.fit_recharge_desc }}</div>
				<div class="fitmoney" :class="{ 'active': rechargeactive == fitdata.id }" @click="fitfun(fitdata.id)">
					{{ fitdata.money }}元
				</div>
			</div>
		</div>
		<div class="vipbox">
			<div class="titlebox">会员充值<span class="des">（{{ moneylist.vip_recharge_desc }}）</span></div>
			<div class="vipmoneybox">
				<div class="item" :class="{ 'active': rechargeactive == item.id }" v-for="(item, index) in vipdata"
					:key="index" @click="vipfun(item, index)">
					<div class="fulltext">充{{ item.money }}元</div>
					<div class="givetext" v-if="item.give_money > 0">送{{ item.give_money }}元</div>
				</div>
			</div>
		</div>
		<div class="btnbox" v-if="isDisable" @click="createOrder">确认充值</div>
		<div class="btnbox" v-if="!isDisable" style="background-color: #BCC5D3;">确认充值</div>
	</div>
</template>

<script>
export default {
	name: 'recharge',
	created () {
		this.getMoneylist()
	},
	data () {
		return {
			fitdata: {},
			vipdata: [],
			moneylist: {},
			rechargeactive: -1,
			isDisable: true,
		}
	},
	methods: {
		goexplain () {
			this.$router.push({ path: '/explain', query: { articleId: '2' } });
		},
		getMoneylist () {
			this.$http.getMoneylist().then(res => {
				if (res.code == 200) {
					this.moneylist = res.data;
					this.fitdata = res.data.list[1][0];
					this.vipdata = res.data.list[2];
					/* this.rechargeactive=res.data.list[1][0].id;
					this.isDisable=true; */
				}
			}).catch(error => {
				console.log(error)
			})
		},
		fitfun (id) {
			this.rechargeactive = id
		},
		vipfun (item, index) {
			this.rechargeactive = item.id
			console.log(item)
		},
		async createOrder () {
			try {
				if (this.rechargeactive == -1) {
					this.$createDialog({
						type: 'alert',
						title: '提示',
						content: '请选择充值金额',
					}).show()
					return false
				}
				this.isDisable = false;
				let result = await this.$http.orderCreate({ id: this.rechargeactive })
				if (result.code == 200) {
					this.$router.push({ path: '/pay', query: { orderId: result.data.order_id } });
				}
				setTimeout(() => {
					this.isDisable = true   //点击一次时隔两秒后才能再次点击
				}, 3000)
			} catch (err) {
				console.log(err)
				setTimeout(() => {
					this.isDisable = true   //点击一次时隔两秒后才能再次点击
				}, 3000)
			}
		},
		goindex () {
			this.$router.push({ path: '/botnav/index' });
		}
	}
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	min-height: 100%;
	position: relative;
	background: url(../assets/topbg.png) no-repeat left top;
	background-size: 100%;
	overflow: hidden;
	z-index: 9;

	.refunddes {
		position: absolute;
		right: 11px;
		top: 15px;
		color: #ffffff;
		font-size: 11px;
		display: flex;
		align-items: center;

		.icon {
			width: 11px;
			height: 11px;
			vertical-align: text-bottom;
			margin-right: 4.5px;
		}
	}

	.logoname {
		width: 158px;
		height: 35px;
		margin: 35px auto 0;
		display: block;
	}

	.titlebox {
		font-size: 15px;
		color: #2A2A2A;
		margin-bottom: 17px;
		font-weight: bold;
		overflow: hidden;
		line-height: normal;

		.des {
			font-size: 12px;
		}
	}

	.fitbox {
		width: 100%;
		margin-top: 48px;
		box-sizing: border-box;
		padding: 0 30px;
		overflow: hidden;

		.fitcont {
			width: 100%;
			display: flex;
			align-items: center;

			.conttext {
				flex: 1;
				box-sizing: border-box;
				padding-right: 19px;
				font-size: 13px;
				color: #2A2A2A;
				line-height: 24px;
			}

			.fitmoney {
				width: 91px;
				height: 52px;
				line-height: 52px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;
				font-size: 16px;
				color: #214087;
				background-color: #E7E7E7;
				font-weight: bold;
				text-align: center;
			}

			.fitmoney.active {
				background: url(../assets/rechargebg.png) no-repeat center center;
				background-size: cover;
				color: #ffffff;
			}
		}
	}

	.vipbox {
		width: 100%;
		margin-top: 45px;
		box-sizing: border-box;
		padding: 0 30px;
		overflow: hidden;

		.vipmoneybox {
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			.item {
				width: 31%;
				height: 64px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-content: center;
				border-radius: 4px;
				background-color: #E7E7E7;
				margin: 0 3.5% 12px 0;
				text-align: center;

				.fulltext {
					font-size: 17px;
					color: #214087;
					width: 100%;
					font-weight: bold;
				}

				.givetext {
					font-size: 13px;
					color: #FAB021;
					width: 100%;
					margin-top: 6px;
				}
			}

			.item:nth-child(3n+3) {
				margin-right: 0;
			}

			.item.active {
				background: url(../assets/rechargebg.png) no-repeat center center;
				background-size: cover;

				.fulltext {
					font-size: 17px;
					color: #ffffff;
				}

				.givetext {
					font-size: 13px;
					color: #ffffff;
				}
			}
		}
	}

	.btnbox {
		width: 184px;
		height: 48px;
		text-align: center;
		line-height: 48px;
		background: #F9A709;
		box-shadow: 0px 4px 9px 1px rgba(33, 64, 135, 0.2);
		opacity: 0.9;
		border-radius: 48px;
		font-weight: 400;
		color: #FFFFFF;
		font-size: 20px;
		margin: 30px auto 10px;
		display: block;
		letter-spacing: 2px;
	}
}
</style>
