import request from '@/utils/request'
// 案例暂时无作用 在接口封装地方会调用到，后面会修改
export function Req_getToken(data) {
  return request.post('/api/user/sign_in',data)
}

//登录接口换取token
export function getLogin(data) {
  return request.get('/v1/user/login',{params: data})
}

//获取广告
export function getBanner(data) {
  return request.get('/v1/common/ad',{params: data})
}

//查询文章
export function getArticledetail(data) {
  return request.get('/v1/common/article',{params: data})
}

//获取系统基础信息
export function getSystemInfo(data) {
  return request.get('/v1/common/system-info',{params: data})
}

//进场 出场
export function getQrcode(data) {
  return request.post('/v1/door/qrcode',data)
}

//包场预约
export function reserveCharter(data) {
  return request.post('/v1/door/reserve-charter',data)
}

//超管二维码
export function getSqrcode(data) {
  return request.post('/v1/door/sqrcode',data)
}

//获取微信jssdk
export function getJsSdk(data) {
  return request.post('/v1/common/js-sdk',data)
}


//我的
export function getUserinfo(data) {
  return request.post('v1/user/info',data)
}

//获取充值金额列表
export function getMoneylist(data) {
  return request.post('v1/common/money-list',data)
}

//创建订单
export function orderCreate(data) {
  return request.post('v1/order/create',data)
}

//获取订单详情
export function getorderDetail(data) {
  return request.post('v1/order/detail',data)
}

//拉起支付
export function orderpay(data) {
  return request.post('v1/order/pay',data)
}

//充值记录
export function getRechargelist(data) {
  return request.post('v1/user/recharge-log',data)
}

//消费记录
export function getSpendlist(data) {
  return request.post('v1/user/consume-log',data)
}

//退款记录
export function getRefundlist(data) {
  return request.post('v1/user/refund-log',data)
}

//申请退款
export function refundApply(data) {
  return request.post('v1/user/apply-refund',data)
}

export function sendCaptcha(data) {
  return request.post('v1/common/send-captcha',data)
}

export function bindMobile(data) {
  return request.post('v1/user/bind-mobile',data)
}

export function getIntegralGoodsList(data) {
  return request.post('v1/integral-goods/list',data)
}

export function getIntegralGoodsInfo(data) {
  return request.post('/v1/integral-goods/info',data)
}

export function exchangeIntegralGoods(data) {
  return request.post('/v1/integral-goods/exchange',data)
}

//退款记录
export function getExchangelist(data) {
  return request.post('v1/user/exchange-log',data)
}

//积分变动记录
export function getIntegrallist(data) {
  return request.post('v1/user/integral-log',data)
}

export function getInviteInfo(data) {
  return request.get('v1/common/invite-info',{params: data})
}
