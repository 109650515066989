import axios from "axios";
import { Dialog } from "cube-ui";
import qs from "qs";
import SHA1 from "./sha1.min";
import store from "@/store"; //引入状态管理仓库
import router from "@/router"; //引入路由
//import { getToken } from '@/utils/auth' //根据业务需求，这个方法是用来获取Token
/* Dialog.$create({
	type: 'alert',
	title: '我是标题',
	content: '我是内容',
	icon: 'cubeic-alert'
}).show() */
const baseURL = "https://api.mambasportclub.com"; //https://api.mambasportclub.com
const transfer_key = "ead5de99e3dfe933ef56bd2ff6e08886";

//排序的函数
function objKeySort(obj) {
  var newkey = Object.keys(obj).sort();
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj; //返回排好序的新对象
}

/**
 * 拼接对象为请求字符串 等同于qs.stringify()
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
function encodeSearchParams(obj) {
  const params = [];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    // 如果值为undefined我们将其置空
    if (typeof value === "undefined") {
      value = "";
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join("="));
  });
  return params.join("&");
}

// 创建axios实例
const service = axios.create({
  baseURL: baseURL, // api 的 base_url
  timeout: 15000, // 请求超时时间
  //withCredentials: true //跨域是否允许携带凭证
});

// request 请求拦截器
service.interceptors.request.use(
  (config) => {
    // console.log('vuex-token-' + store.state.token)
    // console.log('localStorage-token-' + localStorage.getItem('token'))
    //在这里根据自己相关的需求做不同请求头的切换，我司是需要使用这两种请求头。
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded; charset=UTF-8";
    if (config.method === "post") {
      if (store.state.token || localStorage.getItem("token")) {
        // 让每个请求携带令牌——['token']作为自定义密钥。
        // 请根据实际情况修改。
        config.headers["Token"] =
          store.state.token || localStorage.getItem("token");
      }
      if (!config.data) {
        // 没有参数时，config.data为null，需要转下类型
        config.data = {};
      }
      let data = objKeySort({ ...config.data });
      // let _str = encodeSearchParams(data) 和下面方法效果一样
      let _str = qs.stringify(data);
      Object.assign(data, {
        sign: SHA1(_str + transfer_key),
      });
      config.data = qs.stringify(data); // qs将对象 序列化成URL的形式，以&进行拼接
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    console.log(error); // 调试
    return Promise.reject(error);
  }
);

// response 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data; //这是响应返回后的结果
    //在这里可以根据返回的状态码对存在响应错误的请求做拦截，提前做处理。

    //以下为我司的处理规则
    // 如果自定义代码不是200，则判断为错误。
    if (res.code != 200) {
      // 重新登陆
      if (res.code == 403) {
        Dialog.$create({
          type: "alert",
          title: "提示",
          content: res.message,
          //icon: 'cubeic-alert',
          confirmBtn: {
            text: "重新登录",
            active: true,
            disabled: false,
            href: "javascript:;",
          },
          onConfirm: () => {
            /* store.dispatch('resetToken').then(() => {
							location.reload();
						}) */
            window.location.href = "/#/entry";
          },
        }).show();
        return;
      }
      Dialog.$create({
        type: "alert",
        title: "提示",
        content: res.message,
        //icon: 'cubeic-alert',
        confirmBtn: {
          text: "确定",
          active: true,
          disabled: false,
          href: "javascript:;",
        },
        onConfirm: () => {
          /* store.dispatch('resetToken').then(() => {
						location.reload();
					}) */
        },
      }).show();
      return Promise.reject(res);
    } else {
      return res;
    }
    //end
  },
  (error) => {
    console.log("err" + error);
    Dialog.$create({
      type: "alert",
      title: "系统错误",
      content: error.message,
      //icon: 'cubeic-alert'
    }).show();
    return Promise.reject(error);
  }
);

export default service;
