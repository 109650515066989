import Vue from "vue";
import Vuex from "vuex";
//导入插件
import createPersistedState from "vuex-persistedstate";

import { Req_getToken } from "@/utils/api.js";
Vue.use(Vuex);

export default new Vuex.Store({
  // 定义状态(变量)
  state: {
    token: "",
    bindMobile: 0,
    userInfo: { id: 0, nickname: "" },
  },
  // 获取状态(变量的值)
  getters: {
    token: (state) => state.token,
  },
  // 修改state里面的变量(修改变量的值)
  mutations: {
    settoken(state, token) {
      state.token = token;
    },
    setbindMobile(state, bindMobile) {
      state.bindMobile = bindMobile;
    },
    setuserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  // Action 触发 mutation 函数,从而修改状态
  actions: {
    resetToken({ commit, state }, param) {
      return new Promise((resolve) => {
        let dataVal = { userName: "nies", password: "111111" };
        Req_getToken(dataVal)
          .then((res) => {
            resolve();
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  // Module 当状态很多时,把状态分开来管理
  modules: {},
  //配置插件
  plugins: [
    createPersistedState({
      storage: sessionStorage, //指明存储在sessionStorage中
    }),
  ],
});
