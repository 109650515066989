import Vue from "vue";
import "./cube-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

// import 'amfe-flexible'

import * as api from "./utils/api.js";
Vue.prototype.$http = api;

import wx from "weixin-js-sdk";
Vue.prototype.$wx = wx;

import * as utils from "./utils/index.js";
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

/*路由守卫 路由发生变化修改页面title */
// router.beforeEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })
/* router.beforeEach((to,from,next)=>{
  //无论是刷新还是跳转路由，第一个进入的就是这个路由前置钩子函数
  store.commit('settoken',localStorage.getItem('token'))
  if(to.meta.requireAuth){
    if(store.state.token){
      next()
    }else{
      next({
        path:'/login',
        query:{redirect:to.fullPath}
      })
    }
  }else{
    next()
  }
}) */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
