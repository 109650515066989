<template>
    <div class="layindex">
        <v-telephone></v-telephone>
        <img class="logoname" src="@/assets/logoname.png" alt="">
        <div class="listtitle">
            <div class="item">订单号</div>
            <div class="item">兑换时间</div>
            <div class="item">商品信息</div>
            <div class="item">状态</div>
        </div>
        <div class="listbox">
            <div class="scroll-list-wrap">
                <cube-scroll ref="scroll" :data="datalist" :options="options" @pulling-down="onPullingDown"
                    @pulling-up="onPullingUp">
                    <div class="itembox" v-for="(item, index) in datalist" :key="index">
                        <div class="item">{{ item.order_sn }}</div>
                        <div class="item">{{ item.create_time }}</div>
                        <div class="item">
                            {{ item.goods_name }}
                            <p> * {{ item.num }}</p>
                            <p>{{ item.total_integral }}</p>
                        </div>
                        <div class="item">
                            {{ item.status_desc }}
                        </div>
                    </div>
                </cube-scroll>
            </div>
        </div>
        <v-nodata v-if="datalist.length == 0" msg="暂无更多相关信息！"></v-nodata>
    </div>
</template>

<script>
import nodata from '@/components/nodata.vue'
import telephone from '@/components/telephone.vue'
export default {
    name: 'Exchangelist',
    components: {
        'v-nodata': nodata,
        'v-telephone': telephone,
    },
    created () {
        this.getExchangelist()
    },
    data () {
        return {
            datalist: [],
            page: 1,
            pullDownRefresh: false,
            pullDownRefreshThreshold: 50,
            pullDownRefreshStop: 40,
            pullDownRefreshTxt: '刷新成功',
            pullUpLoad: true,
            pullUpLoadThreshold: 10,
            pullUpLoadMoreTxt: '上滑加载更多',
            pullUpLoadNoMoreTxt: '我的有底线的',
        }
    },
    computed: {
        options () {
            return {
                pullDownRefresh: this.pullDownRefreshObj,
                pullUpLoad: this.pullUpLoadObj,
                scrollbar: false
            }
        },
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                // Do not need to set stop value, but you can if you want
                //stop: parseInt(this.pullDownRefreshStop),
                txt: this.pullDownRefreshTxt,
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: {
                    more: this.pullUpLoadMoreTxt,
                    noMore: this.pullUpLoadNoMoreTxt
                },
                visible: true,
            } : false
        }
    },
    methods: {
        onPullingDown () {
            this.page = 1;
            this.getExchangelist()
        },
        onPullingUp () {
            this.page = this.page + 1;
            this.getExchangelist()
        },
        getExchangelist () {
            this.$http.getExchangelist({
                'p': this.page
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.list.length > 0) {
                        this.datalist = this.datalist.concat(...res.data.list)
                        // if(res.data.list.length<10){
                        // 	this.$refs.scroll.forceUpdate()
                        // }
                    } else {
                        this.$refs.scroll.forceUpdate()
                    }

                }
            }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style scoped lang="less">
.layindex {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 150px 0 0;
    position: relative;
    background: url(../assets/topbg.png) no-repeat left top;
    background-size: 100%;
    overflow: hidden;
    z-index: 9;

    .telbox {
        position: absolute;
        box-sizing: border-box;
        width: 57px;
        padding: 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        right: 10px;
        top: 10px;
        z-index: 10;
        background-color: #F9A709;
        border-radius: 20px;

        .icontel {
            width: 14px;
            height: 15px;
        }

        .teltext {
            font-size: 12px;
            color: #FFFFFF;
        }
    }

    .logoname {
        width: 158px;
        height: 35px;
        //margin: 35px auto 0;
        display: block;
        position: absolute;
        left: 50%;
        top: 35px;
        transform: translate(-50%, 0);
    }

    .listtitle {
        position: absolute;
        left: 50%;
        top: 130px;
        transform: translate(-50%, 0);
        width: 100%;
        display: flex;
        align-items: center;
        // margin-bottom: 20px;

        .item {
            flex: 1;
            text-align: center;
            color: #6468B6;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }

    .listbox {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;

        // margin-top: 57px;
        .itembox {
            width: 100%;
            display: flex;
            align-items: center;
            // margin-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 0;

            .item {
                flex: 1;
                text-align: center;
                color: #2A2A2A;
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 16px;
                width: calc(25% - 10px);
                word-wrap: break-word;
                padding: 0 5px;
            }
        }
    }

    .scroll-list-wrap {
        height: 100%;
        overflow: hidden;
        // border: 1px solid rgba(0, 0, 0, 0.1);
    }

}
</style>
