<template>
	<div class="laytelbox" @click="phoneCall">
		<img class="icontel" src="@/assets/icon-tel.png" alt="">
		<span class="teltext">电话</span>
	</div>
</template>

<script>
export default {
	name: 'telephone',
	props: {
		tel: String
	},
	methods: {
		phoneCall: function () {
			let phone = this.tel || localStorage.getItem('systemTelephone')
			console.log(phone)
			this.$createDialog({
				type: 'confirm',
				title: '客服电话',
				content: `${phone}`,
				//icon: 'cubeic-alert',
				confirmBtn: {
					text: '拨打',
					active: true,
					disabled: false,
					href: 'javascript:;'
				},
				cancelBtn: {
					text: '取消',
					active: false,
					disabled: false,
					href: 'javascript:;'
				},
				onConfirm: () => {
					window.location.href = 'tel://' + phone
				},
			}).show()
		}
	}
}
</script>

<style lang="less" scoped>
.laytelbox {
	position: fixed;
	box-sizing: border-box;
	padding: 4px 8px;
	display: flex;
	align-items: center;
	right: 10px;
	top: 10px;
	z-index: 10;
	background-color: #F9A709;
	border-radius: 20px;

	.icontel {
		width: 14px;
		height: 15px;
		margin-right: 4px;
	}

	.teltext {
		font-size: 12px;
		color: #FFFFFF;
	}
}
</style>
